<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>{{
        currentProject.name 
      }}</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="文件资料列表" icon="ios-contacts">
            <file-list
              :columnsFormatData="columnsFormatData"
              :askData="askData"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="projectId"
              v-model="selectedRow"
            ></file-list>
            <Button
            :disabled="selectedRow.length===0"
            class="export-button"
            @click="uploadSet()"
          >设置上传人</Button>
          <Button
            :disabled="selectedRow.length===0"
            class="export-button"
            @click="verifySet()"
          >设置审核人</Button>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        v-model="modalSwicth.uploadSw"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('uploadSw')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>设置上传人</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('uploadSw')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Form
              ref="uploadSw"
              :model="user"
              :rules="ruleValidate"
              :label-width="250"
            >
              <FormItem label="姓名：" prop="name" style="width: 500px">
                <Input v-model="user.name" />
              </FormItem>
              <FormItem label="手机号：" prop="account" style="width: 500px">
                <Input v-model="user.account" placeholder />
              </FormItem>
            </Form>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit('uploadSw')"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button @click="handleReset" size="large" style="margin-left: 8px"
            >重置</Button
          >
          <Button
            size="large"
            @click="closeModal('uploadSw')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.verifySw"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('verifySw')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>设置审核人</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('verifySw')"
        ></i>
        <div class="sc-content-wrapper">
          <Form
            ref="verifySw"
            :model="user"
            :rules="ruleValidate"
            :label-width="250"
          >
            <FormItem label="姓名：" prop="name" style="width: 500px">
              <Input v-model="user.name" />
            </FormItem>
            <FormItem label="手机号：" prop="account" style="width: 500px">
              <Input v-model="user.account" placeholder />
            </FormItem>
          </Form>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit('verifySw')"
            :loading="loadingStatus"
            size="large"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button @click="handleReset" size="large" style="margin-left: 8px"
            >重置</Button
          >
          <Button
            size="large"
            @click="closeModal('verifySw')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import fileList from "@/components/Common/FileList.vue";

let userToken = localStorage.getItem("current_user_token");
// let authData = JSON.parse(localStorage.getItem("authData"));

function userFac() {
  return {
    id: 0,
    name:"",
    account:""
  };
}

export default {
  name: "projectContent",
  components: {
    fileList,
  },
  props: {
    projectId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      askData: "targetFileListOfProject",
      update_count: 1,
      selectedRow: [],
      currentProject: {
        standard_no: "",
        name: "",
      },
      columnsFormatData: [
        {
          type: "selection",
          width: 60,
          align: "center",
          fixed: "left"
        },
        {
          title: "文件名称",
          width: 200,
          align: "center",
          key: "name",
        },
        {
          title: "涉及专业",
          key: "major",
          align: "center",
          width:100,
          render: (h, p) => {
            let majorList = [];
            for(let i of p.row.major.split(';')){
              for(let j of JSON.parse(i)){
                majorList.push(j.name);
              }
            }
            return h(
              "p",
              {},
              majorList.join(',')
            );
          }
        },
        {
          title: "涉及标准条目",
          key: "related_clause",
          align: "center",
          render: (h, p) => {
            let clauseList = JSON.parse(p.row.related_clause);
            return h(
              "div",
              clauseList.map(val=>{
                return h('p',{},
                  val.standard_no+'《'+val.standard_name+'》'+val.clause_no+'：'+val.content
                );
              })
            );
          }
        },
        {
          title: "上传人",
          key: "owner_name",
          align: "center",
          width:100,
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.owner_name?p.row.owner_name:'暂无'
            );
          }
        },
        {
          title: "审核人",
          key: "verify_name",
          align: "center",
          width:100,
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.verify_name?p.row.verify_name:'暂无'
            );
          }
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width:100
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          render: (h, params) => {
            let sw = {askData:'closeFile',name:'关闭'};
            if(params.row.stat==='已关闭'){
              sw = {askData:'restoreFile',name:'复原'};
            }
            return h("div", [
              
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row,sw);
                    },
                  },
                },
                sw.name
              ),
            ]);
          },
        },
      ],
      modalSwicth: {
        uploadSw: false,
        verifySw: false,
      },
      user:userFac(),
      ruleValidate: {
        name: [
          {
            required: true,
            type: "string",
            message: "请输入姓名！",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            type: "string",
            message: "请输入手机号！",
            trigger: "blur",
          },
          {
            min: 11,
            max:11,
            message: "手机号的长度有误！",
            trigger: "blur",
          },
        ],
      },
      loadingStatus: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "projectInfo");
          form.append("project_id", this.projectId);
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/project_manage", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.currentProject = response.data.res_record;
              } else {
                this.$Message.error(response.data.errorInfo);
              }
            })
            .catch((error) => {
              this.$Message.error(error + "未知错误！");
            });
    },
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    // getStaticOption(){
    //   this.$axios
    //       .post(this.$url + "/ask_static_options", {
    //         userToken: userToken,
    //         askData:'ClauseManage'
    //       })
    //       .then(response => {
    //         if (response.data.stat == "ok") {
    //           this.ClauseOptions = response.data.res_record;
    //         } else {
    //           this.$Massage.error("获取选项数据失败，网络存在问题！");
    //         }
    //       });
    // },
    uploadSet(){
      this.modalSwicth.uploadSw = true;
    },
    verifySet(){
      this.modalSwicth.verifySw = true;
    },
    confirmBox(value,sw) {
      this.$Modal.confirm({
        title: "确认"+sw.name+"：",
        content:
          "<p>" +
          "文件：" +
          value.name +
          "</p>",
        onOk: () => {
          this.recordFormatDel(value.id,sw);
        },
        onCancel: () => {},
      });
    },
    recordFormatDel(id,sw) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", sw.askData);
      form.append("id", id);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success(sw.name+"成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    handleReset() {
      for (let item in this.user) {
        this.user[item] = "";
      }
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", name);
          form.append("selectedRow", JSON.stringify(this.selectedRow));
          form.append("user", JSON.stringify(this.user));
          form.append("project_id", this.projectId);
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + "/file_manage", form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("设置成功！");
                this.update_count++;
                this.modalSwicth[name] = false;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}

.export-button {
  position: relative;
  bottom: 48px;
  left: 11px;
  margin-left:10px;
}
</style>